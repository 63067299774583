import React, { useState, useEffect } from "react";
import styled from "styled-components";
import TopNavbar from "../components/Nav/TopNavbar";
import Footer from "../components/Sections/Footer";
import BlogCard from "../components/Elements/BlogCard";
import "./blog.css";

export const BlogNavigation = ({ blogs = [] }) => {
  const [searchQuery, setSearchQuery] = useState("");
  useEffect(() => {
    setTimeout(() => {
      document.getElementById("se").focus();
    }, 0);
  }, []);

  const filteredBlogs = blogs.filter((blog) =>
    (blog.title || "").toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <>
      <TopNavbar />
      <NavWrapper>
        <SearchBarWrapper>
          <SearchInput
            id="se"
            type="text"
            placeholder="Search for blogs..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </SearchBarWrapper>
        <BlogList>
          {filteredBlogs.map((blog, index) => (
            <BlogCard
              id={blog.id}
              key={index}
              thumbnail={blog.images[0]}
              title={blog.title}
              description={blog.description}
              publishDate={blog.publishDate}
            />
          ))}
        </BlogList>
      </NavWrapper>
      <Footer />
    </>
  );
};

const NavWrapper = styled.div`
  display: flex;
  min-height: calc(100vh - 212px);
  flex-direction: column;
  align-items: center;
  margin-top: 100px;
  width: 100%;
  padding: 20px;
`;

const SearchBarWrapper = styled.div`
  margin-bottom: 20px;
  width: 100%;
  max-width: 600px;
`;

const SearchInput = styled.input`
  width: 90%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const BlogList = styled.div`
  display: grid;
  margin-top: 20px;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  gap: 30px;
  width: 100%;
  height: 100%;
  max-width: 1200px;
  justify-content: center;
  padding: 0;
`;

export default BlogNavigation;
