import React from "react";
import styled from "styled-components";
import ServiceBox from "../Elements/ServiceBox";
import Document from "../../assets/svg/Document.jsx";
import Talk from "../../assets/svg/Talk.jsx";
import RedTeam from "../../assets/svg/RedTeam.jsx";

const Services = () => {
  return (
    <SectionWrapper>
      <TextWrapper>
        <Title>Our Services</Title>
      </TextWrapper>
      <ServiceBoxRow className="flex">
        <ServiceBoxWrapper>
          <ServiceBox
            Image={Document}
            title="EU AI Act Compliance"
            description="Ensure your AI systems meet European AI regulations with ease, building trust and staying ahead in innovation."
          />
        </ServiceBoxWrapper>
        <ServiceBoxWrapper>
          <ServiceBox
            Image={Talk}
            title="AI Security Consultation"
            description="Safeguard your AI systems with expert guidance, ensuring robust security and peace of mind."
          />
        </ServiceBoxWrapper>
        <ServiceBoxWrapper>
          <ServiceBox
            Image={RedTeam}
            title="AI auditing services"
            description="Ensure compliance, trust, performance with our comprehensive AI auditing services."
          />
        </ServiceBoxWrapper>
      </ServiceBoxRow>
    </SectionWrapper>
  );
};

const SectionWrapper = styled.section`
  width: 100%;
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: transparent;
`;

const TextWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Title = styled.h1`
  font-size: 3rem;
  color: var(--text);
  margin-bottom: 20px;
`;

const ServiceBoxRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 20px;

  @media (max-width: 1350px) {
    flex-direction: column;
  }
`;

const ServiceBoxWrapper = styled.div`
  width: 33%;
  padding: 40px 50px;

  @media (max-width: 1350px) {
    width: 100%;
    text-align: center;
    padding: 20px 10px;
  }
`;

export default Services;
