import React, { useRef, useState } from "react";
import styled from "styled-components";
import FullButton from "../Buttons/FullButton";
import "./Contact.css";

export default function ContactSection() {
  const mailRef = useRef(null);
  const messageRef = useRef(null);
  const [status, setStatus] = useState("");
  const [agreedToTerms, setAgreedToTerms] = useState(false);

  function validateEmail(email) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  async function submit(event) {
    event.preventDefault();
    setStatus("");
  
    if (!agreedToTerms) {
      setStatus("You have to agree to the Terms and Conditions!");
      clearStatusAfterTimeout();
      return;
    }
  
    const submitButton = document.getElementById("submitButton");
    submitButton.disabled = true;
    submitButton.innerHTML = "Submitting...";
  
    const mail = mailRef.current.value;
    const message = messageRef.current.value;
  
    if (!validateEmail(mail)) {
      setStatus("Your email is invalid!");
      submitButton.disabled = false;
      submitButton.innerHTML = "Submit";
      clearStatusAfterTimeout();
      return;
    }
  
    try {
      const response = await fetch(
        "https://7p139ij7f4.execute-api.eu-central-1.amazonaws.com/nmi/contact",
        {
          method: "POST",
          body: JSON.stringify({ mail, message }),
          headers: { "Content-Type": "application/json" },
        }
      );
  
      if (response.ok) {
        const r = await fetch(
          "https://7p139ij7f4.execute-api.eu-central-1.amazonaws.com/nmi/notify",
          {
            method: "POST",
            body: JSON.stringify({ mail, message }),
            headers: { "Content-Type": "application/json" },
          }
        );
  
        if (r.ok) {
          setStatus("Your message has been sent!");
          mailRef.current.value = "";
          messageRef.current.value = "";
        } else {
          setStatus("Notification failed!");
        }
      } else {
        setStatus("Submission failed!");
      }
    } catch (error) {
      console.error("Error:", error);
      setStatus("An error has occurred!");
    } finally {
      submitButton.disabled = false;
      submitButton.innerHTML = "Submit";
      clearStatusAfterTimeout();
    }
  }

  function clearStatusAfterTimeout() {
    setTimeout(() => {
      setStatus("");
    }, 3000);
  }

  return (
    <Wrapper id="contact">
      <div style={{display: "flex", flexDirection: "column", justifyContent:"center", alignItems: "center"}}>
        <div className="hero-text-contact">
          Ready to take your first steps towards an AI secure future? 
        </div>
        <div className="sub-text-contact">
          Shoot us a message and let's get in touch!
        </div>
      </div>
      <Form onSubmit={submit}>
        <InputWrapper>
          <input
            style={styles.inputs}
            type="text"
            id="mail"
            name="mail"
            className="font20"
            ref={mailRef}
            placeholder="Email"
          />
        </InputWrapper>
        <InputWrapper>
          <MessageTextArea
            id="message"
            name="message"
            className="font20 extraBold"
            ref={messageRef}
            rows="5"
            placeholder="Message"
          />
        </InputWrapper>
        <div className="checkbox_wrapper" style={styles.checkbox_wrapper}>
          <input
            type="checkbox"
            id="termsCheckbox"
            name="termsCheckbox"
            style={styles.checkbox}
            checked={agreedToTerms}
            onChange={(e) => setAgreedToTerms(e.target.checked)}
          />
          <label htmlFor="termsCheckbox" className="font20">
            I accept the{" "}
            <a
              href="/terms-and-conditions"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span className="terms-text">Terms and Conditions</span>
            </a>
          </label>
        </div>
        <SumbitWrapper className="flex" style={{justifyContent: "center", marginBottom: "30px"}}>
          <FullButton
            type="submit"
            title="Submit"
            className="pointer animate radius8"
            id="submitButton"
            width={"100%"}
          />
        </SumbitWrapper>
        <p className="font20" style={{color: "#ff3333"}}>{status}</p> {/* Status message displayed here */}
      </Form>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
  justify-content: center;
  align-items: center;
`;

const Form = styled.form`
  padding: 70px 0 30px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  input,
  textarea {
    padding: 10px;
    margin-top: 10px;
    width: 90%;
    background-color: transparent;
    outline: none;
    box-shadow: none;
    border: 1px solid #feb343;
    margin-bottom: 30px;
    box-sizing: border-box;
  }
  textarea {
    min-height: 300px;
    resize: none;
  }

  input[type="checkbox"] {
    margin-right: 10px;
  }
`;

const SumbitWrapper = styled.div`
align-items: center;
min-width: 200px;
`;

const MessageTextArea = styled.textarea`
  width: 100%;
  height: 100px;
  padding: 10px 0;
  box-sizing: border-box;
  border-radius: 4px;
  color: var(--text);
  /* Scrollbar styling */
  &::-webkit-scrollbar {
    width: 8px;
    background-color: #f5f5f5;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 4px;
  }
  &::-webkit-scrollbar-track {
    background-color: #f0f0f0;
  }
`;

const InputWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;
`;

const styles = {
  inputs: {
    color: "var(--text)",
  },
  checkbox_wrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "30px",
    width: "90%",
    display: "flex",
    justifyContent: "start-left",
  },
  checkbox: {
    width: "15px",
    marginBottom: "0px",
    marginTop: "0px",
  },
};
