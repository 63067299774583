import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

const BlogCard = ({ id, thumbnail, title, description, publishDate }) => {
  const navigate = useNavigate();

  return (
    <CardWrapper onClick={() => navigate(`/blogs/${id}`)}>
      <Thumbnail src={thumbnail} alt={title} />
      <ContentWrapper>
        <Title>{title}</Title>
        <Description>{description}</Description>
        <Date>{publishDate}</Date>
      </ContentWrapper>
    </CardWrapper>
  );
};

const CardWrapper = styled.div`
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  background-color: #fff;
  height: 100%;
  min-height: 400px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.3s ease;
  cursor: pointer;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
  }
`;

const Thumbnail = styled.img`
  width: 100%;
  height: 150px;
  object-fit: cover;
`;

const ContentWrapper = styled.div`
  padding: 15px;
`;

const Title = styled.h3`
  font-size: 1.2rem;
  color: var(--text);
  margin-bottom: 10px;
`;

const Description = styled.p`
  font-size: 0.9rem;
  color: #666;
  line-height: 1.4;
  margin-bottom: 10px;
`;

const Date = styled.span`
  font-size: 0.8rem;
  color: #999;
`;

export default BlogCard;
