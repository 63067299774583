import React from "react";
import styled from "styled-components";

const HowWeDoIt = () => {
  const steps = [
    { "number": "1", "title": "Determination of the Risk Factor", "description": "Identify potential risks and vulnerabilities in AI systems to ensure they are addressed early in the development process." },
    { "number": "2", "title": "Review of Ethical Values", "description": "Assess the system's alignment with ethical principles, ensuring fairness, transparency, and respect for societal values." },
    { "number": "3", "title": "Impact Assessment", "description": "Evaluate the potential societal, economic, and environmental impact of deploying the AI system." },
    { "number": "4", "title": "Data Validation", "description": "Ensure the quality, accuracy, and relevance of the data used to train and operate the AI system." },
    { "number": "5", "title": "Model Validation", "description": "Verify that the AI model performs as intended and meets the desired performance and safety standards." },
    { "number": "6", "title": "Evaluation of Model Integration", "description": "Assess how the AI model integrates into the broader system, ensuring compatibility and seamless operation." },
    { "number": "7", "title": "Update Analysis", "description": "Examine updates and modifications to the AI system to ensure they do not introduce new risks or vulnerabilities." },
    { "number": "8", "title": "Decommissioning Analysis", "description": "Plan for the safe and responsible decommissioning of the AI system when it reaches the end of its lifecycle." }
];


  return (
    <SectionWrapper>
      <Title>How We Do It</Title>
      <CardsWrapper>
        {steps.map((step, index) => (
          <Card key={index}>
            <Number>{step.number}</Number>
            <ContentWrapper>
              <CardTitle>{step.title}</CardTitle>
              <CardDescription>{step.description}</CardDescription>
            </ContentWrapper>
          </Card>
        ))}
      </CardsWrapper>
    </SectionWrapper>
  );
};

const SectionWrapper = styled.section`
  width: 100%;
  padding: 50px 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 1350px){
    padding: 50px 20px;
  }
`;

const Title = styled.h1`
  font-size: 3rem;
  color: var(--text);
  margin-bottom: 40px;
  text-align: center;
`;

const CardsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  width: 100%;
  height: 100%;

  @media (max-width: 1150px) {
    grid-template-columns: 1fr;
  }
`;

const Card = styled.div`
  position: relative;
  background: white;
  height: 200px;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 20px;
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
  }
`;

const Number = styled.div`
  position: absolute;
  top: 10px;
  left: 10px;
  font-size: 10rem;
  color: #b861b8;
  font-weight: bold;
  font-family: Roboto;
  opacity: 0.3;
  z-index: 1;
`;

const ContentWrapper = styled.div`
  position: relative;
  padding: 20px;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @media (max-width: 600px) {
    padding: 0px;
  }
`;

const CardTitle = styled.h3`
  font-size: 1.75rem;
  color: var(--text);
  margin-bottom: 10px;
  @media (max-width: 600px) {
    font-size: 1.5rem;
  }
`;

const CardDescription = styled.p`
  font-size: 1.25rem;
  color: var(--secondarytext);
  line-height: 1.6;
  @media (max-width: 600px) {
    font-size: 1rem;
  }
`;

export default HowWeDoIt;
