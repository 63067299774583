import React from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import TopNavbar from "../components/Nav/TopNavbar";
import Footer from "../components/Sections/Footer";

export const Blog = ({ blogs }) => {
    const { id } = useParams();

    const blog = blogs.find((b) => b.id === parseInt(id));

    return (
        <>
            <TopNavbar />
            <BlogWrapper>
                <BlogContent>
                    {blog ? (
                        <>
                            <h1>
                                {blog.title}
                            </h1>
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: blog.content
                                }}
                            />
                        </>
                    ) : (
                        <p>Blog not found...</p>
                    )}
                </BlogContent>
            </BlogWrapper>
            <Footer />
        </>
    );
};

const BlogWrapper = styled.div`
    display: flex;
    margin-top: 100px;
    width: 100%;
    justify-content: center;
    padding: 20px;
    background-color: #381938;
`;

const BlogContent = styled.div`
    width: 100%;
    background: #381938;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 8px;
    padding: 20px;
    border-radius: 8px;
    line-height: 1.6;
    font-size: 16px;
    color: #feb343;
`;

export default Blog;
