import * as React from "react";

function SvgComponent(props) {
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width='18'
            height='18'
            fill='none'
            viewBox='0 0 18 18'
        >
            <path
            stroke='#141414'
            strokeLinecap='round'
            strokeLinejoin='round'
            d='M1 17 17 1v16M1 1h16'
            ></path>
        </svg>
    );
}

export default SvgComponent;
