import React from "react";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import styled from "styled-components";
import BlogCard from "../Elements/BlogCard.jsx";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const BlogCarousel = ({ blogs }) => {
  const navigate = useNavigate();
  const itemsToShow = 3;

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: itemsToShow,
    slidesToScroll: 1,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          prevArrow: <CustomPrevArrow />,
          nextArrow: <CustomNextArrow />,
        },
      },
    ],
  };

  return (
    <CarouselContainer>
      <TitleWrapper>
        <SectionTitle>Latest Blogs</SectionTitle>
      </TitleWrapper>
      <CarouselWrapper>
        <Slider {...settings}>
          {blogs.map((blog, index) => (
            <CarouselItem key={index}>
              <BlogCard
                id={blog.id}
                thumbnail={blog.images[0]}
                title={blog.title}
                description={blog.description}
                publishDate={blog.publishDate}
              />
            </CarouselItem>
          ))}
          <CarouselItem>
            <SpecialCard>
              <SpecialContent>
                <SpecialTitle>Want to read more?</SpecialTitle>
                <SpecialDescription>
                  Check out all of our blogs and stay updated with the latest news.
                </SpecialDescription>
                <SpecialButton onClick={() => navigate("/blogs")}>Explore Blogs</SpecialButton>
              </SpecialContent>
            </SpecialCard>
          </CarouselItem>
        </Slider>
      </CarouselWrapper>
    </CarouselContainer>
  );
};

const CustomPrevArrow = ({ onClick }) => (
  <ArrowWrapper onClick={onClick} style={{ left: '-40px' }}>
    &#60; {/* Left arrow symbol */}
  </ArrowWrapper>
);

const CustomNextArrow = ({ onClick }) => (
  <ArrowWrapper onClick={onClick} style={{ right: '-40px' }}>
    &#62; {/* Right arrow symbol */}
  </ArrowWrapper>
);

const ArrowWrapper = styled.div`
  font-size: 1.5rem;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
  background: #b861b8;
  color: white;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
  transition: background 0.3s ease;

  transition: opacity 0.3s ease, transform 0.3s ease;

  &:hover {
    background-color: #9a4a9a;
  }
`;

const CarouselContainer = styled.div`
  width: 100%;
  padding: 20px;

  @media (min-width: 1050px) {
    display: none;
  }
`;

const TitleWrapper = styled.div`
  text-align: center;
  margin-bottom: 20px;
`;

const SectionTitle = styled.h2`
  font-size: 2rem;
  color: var(--text);
`;

const CarouselWrapper = styled.div`
  width: 100%;
  padding: 20px;

  .slick-slide {
    padding: 0 10px;
  }
`;

const CarouselItem = styled.div`
  height: 100%;
  display: flex;
  align-items: stretch;
`;

const SpecialCard = styled.div`
  border: 1px solid #b861b8;
  border-radius: 10px;
  background-color: #ffe6ff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  text-align: center;
  height: 100%;
  box-sizing: border-box;
`;

const SpecialContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const SpecialTitle = styled.h3`
  font-size: 1.5rem;
  color: #b861b8;
  margin-bottom: 10px;
`;

const SpecialDescription = styled.p`
  font-size: 1rem;
  color: #666;
  margin-bottom: 20px;
`;

const SpecialButton = styled.button`
  padding: 10px 20px;
  background-color: #b861b8;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #9a4a9a;
  }
`;

export default BlogCarousel;
