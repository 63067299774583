import {BrowserRouter as Router, Routes, Route } from "react-router-dom";
import React from "react";
import {LandingPage} from "./pages/landing";
import {NotFound} from "./pages/404";
import {Blog} from "./pages/blog";
import {BlogNavigation} from "./pages/blog_navigation";
import {TermsAndConditions} from "./pages/terms_and_conditions.js";

export const Routing = ({ blogs }) => {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<LandingPage blogs={blogs} />} />
                <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
                <Route path="/blogs/:id" element={<Blog blogs={blogs} />} />
                <Route path="/blogs" element={<BlogNavigation blogs={blogs} />} />
                <Route path="*" element={<NotFound />} />
            </Routes>
        </Router>
    );
};