import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";
import Sidebar from "../Nav/Sidebar";
import Backdrop from "../Elements/Backdrop";
import logoWithText from "../../assets/img/logo.png";
import FullButton from "../Buttons/FullButton";
import { useNavigate } from "react-router-dom";
import Contact from "../../assets/svg/Contact";
import "./TopNavbar.css";

export default function TopNavbar() {
  const [sidebarOpen, toggleSidebar] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [logoMaxWidth, setLogoMaxWidth] = useState(0); // State to store calculated max-width
  const wrapperRef = useRef(null); // Ref to access the Wrapper DOM element
  const navigate = useNavigate();

  // Handle scroll effect
  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 0);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const calculateLogoMaxWidth = () => {
      if (wrapperRef.current) {
        const wrapperHeight = wrapperRef.current.offsetHeight;
        setLogoMaxWidth(wrapperHeight * 0.9 * 3.5);
      }
    };

    calculateLogoMaxWidth();
    window.addEventListener("resize", calculateLogoMaxWidth);
    return () => {
      window.removeEventListener("resize", calculateLogoMaxWidth);
    };
  }, []);

  return (
    <>
      <Sidebar sidebarOpen={sidebarOpen} toggleSidebar={toggleSidebar} />
      {sidebarOpen && <Backdrop toggleSidebar={toggleSidebar} />}
      <Wrapper
        className={`flexCenter animate whiteBg ${isScrolled ? "scrolled" : ""}`}
        ref={wrapperRef}
      >
        <NavInner>
          <LogoWrapper>
            <NavLink exact="true" to="/" className="logo-wrapper" style={styles.link_nav}>
              <Logo
                src={logoWithText}
                alt="Logo"
                style={{
                  maxWidth: `${logoMaxWidth}px`,
                }}
              />
          </NavLink>
          </LogoWrapper>
          <ButtonsWrapper>
            <ContactButton title="Contact us" action={() => toggleSidebar(!sidebarOpen)} width="200px">
              <Contact />
            </ContactButton>
          </ButtonsWrapper>
        </NavInner>
      </Wrapper>
    </>
  );
}

const Wrapper = styled.nav`
  background: rgba(242, 242, 242, 0.2);
  height: 7.5%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  transition: background-color 0.3s ease, height 0.3s ease;

  &.scrolled {
    background: rgba(242, 242, 242, 0.95);
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(6px);
  }
`;

const NavInner = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  width: 100%;
  padding: 10px;
  gap: 10px;
`;

const ButtonsWrapper = styled.div`
  width: 50%;
  display: flex;
  justify-content: right;
  align-items: center;
  vertical-align: middle;
`;

const LogoWrapper = styled.div`
  width: 50%;
  display: flex;
  justify-content: left;
  align-items: center;
  vertical-align: middle;
`

const ContactButton = styled(FullButton)`
  margin-right: 20px;
  padding: 5px;
  max-width: 200px;
  width: 100%;
  @media (max-width: 500px) {
    font-size: 1rem;
    padding: 10px;
    max-width: 150px;
    margin-right: 0px;
  }
`;

const Logo = styled.img`
  width: 100%; /* Logo width adjusts to container */
  height: auto; /* Maintain aspect ratio */
`;
const styles = {
  link_nav: {
    marginLeft: "20px",
    marginTop: "2.5px",
    height: "100%",
    display: "flex",
    verticalAlign: "middle",
    alignItems: "center",
  },
};
