import React from 'react';
import TopNavbar from "../components/Nav/TopNavbar";
import FourOFour from "../assets/svg/404.jsx";

export const NotFound = () => {
    return (
        <div style={styles.container}>
            <TopNavbar />
            <FourOFour style={{width: "100%", height: "80vh"}}/>
        </div>
    );
};

const styles = {
  container: {
    textAlign: 'center',
    marginTop: '10%',
    fontFamily: 'Arial, sans-serif',
  },
};

export default NotFound;