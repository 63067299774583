import React, { useEffect, useState } from "react";
import { Routing } from "./Routes";
import { Helmet } from "react-helmet";

export default function App() {
  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const response = await fetch(
          "https://7p139ij7f4.execute-api.eu-central-1.amazonaws.com/nmi/blogs",
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        if (response.ok) {
          const data = await response.json();
          if (data.blogs && Array.isArray(data.blogs)) {
            const processedBlogs = data.blogs.map((blog) => ({
              id: blog.id,
              title: blog.title,
              content: blog.content,
              publishDate: blog.publishDate,
              tags: blog.tags,
              images: blog.images,
              thumbnail: blog.thumbnail,
              description: blog.description,
            }));
            setBlogs(processedBlogs);
          } else {
            console.error("Invalid blog data format");
          }
        } else {
          console.error("Failed to fetch blogs", response.statusText);
        }
      } catch (error) {
        console.error("Error fetching blogs", error);
      }
    };

    fetchBlogs();
  }, []);

  return (
    <>
      <Helmet>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link
          href="https://fonts.googleapis.com/css2?family=Khula:wght@400;600;800&display=swap"
          rel="stylesheet"
        />
        <meta
          name="description"
          content="Ensure compliance with the EU AI Act in Romania with NMI Guard's expert audit services. Our comprehensive AI audits help businesses meet regulatory standards, ensuring transparency, accountability, and safety in AI deployments."
        />
      </Helmet>
      <Routing
        blogs={blogs}
      />
    </>
  );
}
