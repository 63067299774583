import React from "react";
import styled from "styled-components";

const ServiceBox = ({ Image, title, description }) => {
  return (
    <BoxWrapper>
      <ImageWrapper>
        <Image />
      </ImageWrapper>
      <ContentWrapper>
        <Title>{title}</Title>
        <Description>{description}</Description>
      </ContentWrapper>
    </BoxWrapper>
  );
};

const BoxWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  text-align: left;
  padding: 20px;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  height: 400px; 
  width: 100%;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
  }
`;

const ImageWrapper = styled.div`
  width: 40%;
  height: 100%;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  background-color: #fff;
  border-radius: 10px 0 0 10px;
  overflow: hidden;

  svg {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

const ContentWrapper = styled.div`
  width: 66%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

const Title = styled.h3`
  font-size: 1.75rem;
  color: var(--text);
  margin-bottom: 10px;

  @media (max-width: 768px) {
   font-size: 1.5rem;
  }
`;

const Description = styled.p`
  font-size: 1.25rem;
  color: var(--secondarytext);
  line-height: 1.4;
`;

export default ServiceBox;
