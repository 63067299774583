import React from "react";
import styled from "styled-components";
import AboutUsImage from "../../assets/svg/AboutUsImage.jsx";

const AboutUs = () => {
  return (
    <SectionWrapper>
      <ContentWrapper>
        <SvgWrapper>
          <AboutUsImage />
        </SvgWrapper>
        <TextWrapper>
          <Title>Young minds pioneering AI security.</Title>
          <Subtitle>
          At NMI Guard, our young, dynamic team includes one of the few AI security researchers, ensuring secure, compliant, and trustworthy AI systems tailored to your needs.
          </Subtitle>
        </TextWrapper>
      </ContentWrapper>
    </SectionWrapper>
  );
};

const SectionWrapper = styled.section`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 50px;
  gap: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
    text-align: center;
  }
`;

const SvgWrapper = styled.div`
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
    order: 2;
    width: 100%;
  }

  @media (min-width: 769px) {
    order: 1;
  }
`;

const TextWrapper = styled.div`
  display: flex;
  width: 50%;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  @media (max-width: 768px) {
    order: 1;
    align-items: center;
    width: 100%;
  }

  @media (min-width: 769px) {
    order: 1; /* Ensure the text stays on the left for desktop */
  }
`;

const Title = styled.h1`
  font-size: 3rem;
  color: var(--text);
  margin-bottom: 20px;
`;

const Subtitle = styled.p`
  font-size: 2rem;
  color: var(--secondarytext);
  line-height: 1.6;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    font-size: 1.5rem;
  }

  @media (max-width: 550px) {
    font-size: 1rem;
  }
`;

export default AboutUs;
