import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import BlogCard from "../Elements/BlogCard.jsx";
import BlogCarousel from "../Elements/BlogCarousel.jsx";
import BlogsImage from "../../assets/svg/BlogsImage.jsx";

const BlogSection = ({ blogs }) => {
  const navigate = useNavigate();

  if (!blogs || blogs.length === 0) {
    return (
      <SectionWrapper>
        <NoBlogsMessage>
          <h2>No blogs available at the moment.</h2>
          <p>Check back later for the latest updates and insights.</p>
        </NoBlogsMessage>
      </SectionWrapper>
    );
  }

  const latestBlogs = blogs.slice(0, 3);

  return (
    <SectionWrapper>
      <ContentWrapper>
        <BlogsWrapper>
          <TitleWrapper>
            <SectionTitle >Latest Blogs</SectionTitle>
          </TitleWrapper>
          <CardsWrapper>
            {latestBlogs.map((blog, index) => (
              <BlogCard
                key={index}
                id={blog.id}
                thumbnail={blog.images[0]}
                title={blog.title}
                description={blog.description}
                publishDate={blog.publishDate}
              />
            ))}

            <SpecialCard>
              <SpecialContent>
                <SpecialTitle>Want to read more?</SpecialTitle>
                <SpecialDescription>
                  Check out all of our blogs and stay updated with the latest news.
                </SpecialDescription>
                <SpecialButton onClick={() => navigate("/blogs")}>Explore Blogs</SpecialButton>
              </SpecialContent>
            </SpecialCard>
          </CardsWrapper>
        </BlogsWrapper>

        <ImageWrapper>
          <BlogsImage />
        </ImageWrapper>
      </ContentWrapper>

      <BlogCarousel blogs={blogs} />
    </SectionWrapper>
  );
};

const SectionWrapper = styled.section`
  width: 100%;
  padding: 50px 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 1350px){
    padding: 50px 20px;
  }
`;

const NoBlogsMessage = styled.div`
  text-align: center;
  color: #b861b8;
  margin-top: 50px;

  h2 {
    font-size: 2rem;
    margin-bottom: 10px;
  }

  p {
    font-size: 1.2rem;
  }
`;

const BlogsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
`;

const TitleWrapper = styled.div`
  width: 100%;
  text-align: center;
  margin-bottom: 50px;
`;

const SectionTitle = styled.h2`
  font-size: 2.5rem;
  color: var(--text);
`;

const ContentWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: 20px;

  @media (max-width: 1050px) {
    display: none;
  }
`;

const CardsWrapper = styled.div`
  display: grid;
  width: 100%;
  height: 100%;
  grid-template-columns: repeat(2, 1fr);
  gap: 5rem;

  @media (max-width: 1050px) {
    display: none;
  }
`;

const ImageWrapper = styled.div`
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 1050px) {
    display: none;
  }
`;

const SpecialCard = styled.div`
  flex: 0 0 auto;
  border: 1px solid #b861b8;
  border-radius: 10px;
  background-color: #ffe6ff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  text-align: center;
  transition: transform 0.3s ease;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
  }
`;

const SpecialContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const SpecialTitle = styled.h3`
  font-size: 1.5rem;
  color: #b861b8;
  margin-bottom: 10px;
`;

const SpecialDescription = styled.p`
  font-size: 1rem;
  color: #666;
  margin-bottom: 20px;
`;

const SpecialButton = styled.button`
  padding: 10px 20px;
  background-color: #b861b8;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #9a4a9a;
  }
`;

export default BlogSection;
