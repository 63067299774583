import React from "react";
import styled from "styled-components";
import TopNavbar from "../components/Nav/TopNavbar";
import Footer from "../components/Sections/Footer";

export const TermsAndConditions = () => {
  return (
    <>
      <TopNavbar />
      <TermsWrapper>
        <TermsContent>
          <h1>Terms and Conditions</h1>
          <p>
            Welcome to our website. By accessing or using our site, you agree to
            comply with and be bound by the following terms and conditions. If
            you disagree with any part of these terms, please do not use our
            website.
          </p>

          <h2>1. Acceptance of Terms</h2>
          <p>
            By accessing our site, you agree to be bound by these terms and
            conditions. If you do not agree, please discontinue use of this
            website.
          </p>

          <h2>2. Changes to Terms</h2>
          <p>
            We reserve the right to update or change these terms at any time
            without prior notice. Your continued use of the website constitutes
            acceptance of the updated terms.
          </p>

          <h2>3. Intellectual Property</h2>
          <p>
            All content on this website, including text, images, and
            trademarks, is the property of the website owner unless otherwise
            stated. Unauthorized use is prohibited.
          </p>

          <h2>4. User Conduct</h2>
          <p>
            Users must not engage in any activity that may harm the website,
            its users, or violate applicable laws.
          </p>

          <h2>5. Limitation of Liability</h2>
          <p>
            We are not liable for any damages or losses arising from the use of
            this website.
          </p>

          <p>
            For further information, feel free to contact us via the details
            provided on our website.
          </p>
        </TermsContent>
      </TermsWrapper>
      <Footer />
    </>
  );
};

const TermsWrapper = styled.div`
  display: flex;
  margin-top: 100px;
  min-height: calc(100vh - 212px);
  width: 100%;
  justify-content: center;
  padding: 20px;
  background-color: #f7f7f7;
`;

const TermsContent = styled.div`
  width: 100%;
  max-width: 800px;
  background: #ffffff;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 8px;
  padding: 20px;
  border-radius: 8px;
  line-height: 1.6;
  font-size: 16px;
  color: #333;

  h1 {
    font-size: 2rem;
    margin-bottom: 20px;
  }

  h2 {
    font-size: 1.5rem;
    margin-top: 20px;
    margin-bottom: 10px;
  }

  p {
    margin-bottom: 10px;
  }
`;

export default TermsAndConditions;
