import * as React from "react";
const SVGComponent = (props) => (
  <svg
    id="Capa_1"
    enableBackground="new 0 0 605.625 605.625"
    viewBox="0 0 605.625 605.625"
    width={300}
    height={300}
    {...props}
  >
    <g width="100%" height="100%" transform="matrix(1,0,0,1,0,0)">
      <g>
        <g clipRule="evenodd" fillRule="evenodd">
          <path
            d="m207.462 370.47h128.278c5.278 0 9.562-4.284 9.562-9.562s-4.284-9.562-9.562-9.562h-128.278c-5.278 0-9.562 4.284-9.562 9.562s4.283 9.562 9.562 9.562z"
            fill="#b861b8"
            fillOpacity={1}
            data-original-color="#000000ff"
            stroke="none"
            strokeOpacity={1}
          />
          <path
            d="m288.188 360.907v230.22c0 5.279 4.284 9.562 9.562 9.562 5.272 0 9.562-4.284 9.562-9.562v-230.22c0-5.279-4.29-9.562-9.562-9.562-5.278 0-9.562 4.284-9.562 9.562z"
            fill="#b861b8"
            fillOpacity={1}
            data-original-color="#000000ff"
            stroke="none"
            strokeOpacity={1}
          />
          <path
            d="m83.404 4.934c-37.217 0-67.441 30.224-67.441 67.447s30.224 67.441 67.441 67.441c37.224 0 67.448-30.217 67.448-67.441s-30.224-67.447-67.448-67.447zm0 19.125c26.673 0 48.323 21.65 48.323 48.322 0 26.667-21.65 48.316-48.323 48.316-26.667 0-48.316-21.649-48.316-48.316 0-26.673 21.65-48.322 48.316-48.322z"
            fill="#b861b8"
            fillOpacity={1}
            data-original-color="#000000ff"
            stroke="none"
            strokeOpacity={1}
          />
          <path
            d="m522.221 4.934c-37.224 0-67.448 30.224-67.448 67.447s30.224 67.441 67.448 67.441c37.217 0 67.441-30.217 67.441-67.441s-30.224-67.447-67.441-67.447zm0 19.125c26.667 0 48.316 21.65 48.316 48.322 0 26.667-21.649 48.316-48.316 48.316-26.673 0-48.323-21.649-48.323-48.316 0-26.673 21.65-48.322 48.323-48.322z"
            fill="#b861b8"
            fillOpacity={1}
            data-original-color="#000000ff"
            stroke="none"
            strokeOpacity={1}
          />
          <path
            d="m136.113 277.038c1.581 3.188 4.858 5.31 8.562 5.31l3.551-.689 88.051-35.196c13.553-5.412 28.949 1.186 34.368 14.739 5.412 13.547-1.192 28.943-14.739 34.361l-116.286 46.48c-11.112 4.443-23.696.848-30.817-8.485-2.486-3.258-6.777-4.571-10.665-3.258-3.882 1.313-6.496 4.96-6.496 9.059v48.023c0 4.858 3.64 8.938 8.46 9.499l77.399 8.938c18.29 2.11 32.098 17.601 32.098 36.019v149.29c0 5.279 4.284 9.562 9.562 9.562 5.279 0 9.562-4.284 9.562-9.562v-149.29c0-28.133-21.088-51.791-49.03-55.016l-68.927-7.962v-19.967c11.029 5.068 23.983 5.693 35.949.912l116.293-46.48c23.345-9.339 34.725-35.872 25.392-59.224s-35.866-34.731-59.217-25.398l-80.555 32.2-46.276-75.741c-6.534-10.697-17.187-18.226-29.446-20.821-.376-.076-.752-.159-1.135-.236-12.846-2.716-26.24.242-36.746 8.115-10.506 7.88-17.104 19.903-18.099 32.997-5.623 73.988-16.9 222.271-16.9 222.271-.02.242-.026.484-.026.727 0 28.101 21.044 51.739 48.947 55.003l69.009 8.912v98.997c0 5.279 4.284 9.562 9.562 9.562s9.562-4.284 9.562-9.562v-107.405c0-4.813-3.57-8.868-8.339-9.486l-77.52-10.002c-18.162-2.097-31.901-17.385-32.098-35.63 0-.051 16.868-221.939 16.868-221.939.58-7.593 4.405-14.573 10.5-19.138 6.101-4.571 13.866-6.286 21.324-4.711l1.128.236c7.115 1.505 13.292 5.878 17.085 12.081 14.465 23.677 43.783 71.668 49.502 81.033.194.305.385.598.583.872z"
            fill="#b861b8"
            fillOpacity={1}
            data-original-color="#000000ff"
            stroke="none"
            strokeOpacity={1}
          />
          <path
            d="m60.193 290.948 30.728 50.305c11.583 18.959 35.158 26.8 55.794 18.551 4.902-1.957 7.293-7.529 5.33-12.425-1.957-4.902-7.529-7.293-12.425-5.336-11.972 4.788-25.653.242-32.379-10.761l-30.727-50.305c-2.754-4.501-8.645-5.922-13.145-3.175-4.502 2.755-5.93 8.645-3.176 13.146z"
            fill="#b861b8"
            fillOpacity={1}
            data-original-color="#000000ff"
            stroke="none"
            strokeOpacity={1}
          />
          <path
            d="m448.15 275.393-92.591 13.094c-24.901 3.519-42.253 26.596-38.734 51.497 3.519 24.894 26.596 42.253 51.497 38.734l113.877-16.11c4.431-.625 8.689-1.887 12.661-3.717v19.966l-68.927 7.962c-27.942 3.226-49.03 26.883-49.03 55.016v149.29c0 5.279 4.284 9.562 9.562 9.562s9.562-4.284 9.562-9.562v-149.29c0-18.417 13.808-33.909 32.098-36.019l77.373-8.938h-.019c4.788-.523 8.504-4.577 8.504-9.499v-48.029c0-4.099-2.614-7.739-6.496-9.052-3.882-1.32-8.173-.006-10.665 3.251-4.188 5.483-10.391 9.148-17.302 10.124l-113.877 16.103c-14.452 2.046-27.84-8.026-29.88-22.478-2.046-14.446 8.026-27.833 22.472-29.88l97.034-13.719-1.313.089 8.16-4.577 51.739-84.692c6.617-10.825 18.736-17.04 31.391-16.084.179.013.351.025.529.038 17.047 1.288 30.453 15.096 31.231 32.168l9.492 207.774c-.102 18.322-13.872 33.705-32.098 35.815l-75.907 8.766c-4.775.548-8.396 4.558-8.46 9.365l-1.492 108.63c-.07 5.279 4.156 9.62 9.429 9.696 5.279.07 9.62-4.156 9.696-9.435l1.371-100.228 67.556-7.797c27.942-3.226 49.03-26.89 49.03-55.016 0-.147-.006-.293-.013-.44 0 0-6.337-138.745-9.499-208.003-1.224-26.737-22.21-48.354-48.896-50.363-.179-.013-.357-.032-.529-.045-19.813-1.492-38.792 8.236-49.151 25.188z"
            fill="#b861b8"
            fillOpacity={1}
            data-original-color="#000000ff"
            stroke="none"
            strokeOpacity={1}
          />
          <path
            d="m393.14 33.621c0-7.605-3.022-14.905-8.402-20.285-5.381-5.381-12.673-8.402-20.285-8.402h-133.41c-15.848 0-28.688 12.846-28.688 28.687v121.533c0 6.247 3.646 11.921 9.333 14.509 5.686 2.588 12.361 1.613 17.072-2.493 9.199-8.013 22.16-19.31 29.382-25.608 1.747-1.517 3.978-2.352 6.286-2.352h100.024c7.612 0 14.905-3.022 20.285-8.402 5.38-5.381 8.402-12.68 8.402-20.285v-76.902zm-171.66 114.527v-114.527c0-5.278 4.278-9.562 9.562-9.562h133.41c2.537 0 4.972 1.007 6.764 2.805 1.791 1.791 2.799 4.22 2.799 6.757v76.902c0 2.537-1.007 4.966-2.799 6.757-1.791 1.798-4.227 2.805-6.764 2.805h-100.023c-6.93 0-13.623 2.505-18.851 7.057z"
            fill="#b861b8"
            fillOpacity={1}
            data-original-color="#000000ff"
            stroke="none"
            strokeOpacity={1}
          />
          <path
            d="m255.147 64.17h86.649c5.279 0 9.562-4.284 9.562-9.562s-4.284-9.562-9.562-9.562h-86.649c-5.278 0-9.562 4.284-9.562 9.562s4.283 9.562 9.562 9.562z"
            fill="#b861b8"
            fillOpacity={1}
            data-original-color="#000000ff"
            stroke="none"
            strokeOpacity={1}
          />
          <path
            d="m255.147 99.042h42.604c5.272 0 9.562-4.284 9.562-9.562 0-5.279-4.29-9.562-9.562-9.562h-42.604c-5.278 0-9.562 4.284-9.562 9.562-.001 5.278 4.283 9.562 9.562 9.562z"
            fill="#b861b8"
            fillOpacity={1}
            data-original-color="#000000ff"
            stroke="none"
            strokeOpacity={1}
          />
          <path
            d="m453.467 366.677 28.732-4.067c13.483-1.906 25.404-9.741 32.506-21.356l30.727-50.305c2.754-4.501 1.326-10.391-3.175-13.145-4.501-2.748-10.391-1.326-13.145 3.175l-30.727 50.305c-4.118 6.738-11.042 11.284-18.864 12.393l-28.732 4.061c-5.228.74-8.874 5.585-8.135 10.812.74 5.22 5.585 8.866 10.813 8.127z"
            fill="#b861b8"
            fillOpacity={1}
            data-original-color="#000000ff"
            stroke="none"
            strokeOpacity={1}
          />
        </g>
      </g>
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
    </g>
  </svg>
);
export default SVGComponent;
