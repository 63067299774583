import React from "react";
import styled from "styled-components";

export default function FullButton({ title, action, border, id, width, className }) {
  return (
    <Wrapper
      className={`animate pointer radius8 ${className || ""}`}
      onClick={action ? () => action() : null}
      border={border}
      id={id}
      width={width}
    >
      {title}
    </Wrapper>
  );
}

const Wrapper = styled.button`
  background-color: #B861B8;
  padding: 5px;
  outline: none;
  color: var(--background);
  font-size: 1.75rem;
  text-transform: capitalize;
  transition: ease 0.2s, box-shadow ease 0.2s;
  border: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  width: ${(props) => props.width || "auto"}; /* Allow custom width */
  :hover {
    background-color: #9a4a9a;
  }
`;
