import React from "react";
import styled from "styled-components";
import Contact from "../Sections/Contact.jsx";
import CloseIcon from "../../assets/svg/CloseIcon";
import logoWithText from "../../assets/img/logo.png";

export default function Sidebar({ sidebarOpen, toggleSidebar }) {
  return (
    <Wrapper className="animate darkBg" sidebarOpen={sidebarOpen}>
      <SidebarHeader className="flexSpaceCenter">
        <div className="flexNullCenter">
          <Logo src={logoWithText} alt=""/>
        </div>
        <CloseBtn onClick={() => toggleSidebar(!sidebarOpen)} className="animate pointer">
          <CloseIcon />
        </CloseBtn>
      </SidebarHeader>
      <SidebarContainer>
        <Contact />
      </SidebarContainer>
    </Wrapper>
  );
}

const Wrapper = styled.nav`
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  width: 550px;
  height: 100%;
  position: fixed;
  background: var(--background);
  top: 0;
  padding: 0 30px;
  right: ${(props) => (props.sidebarOpen ? "0px" : "-550px")};
  z-index: 9999;
  @media (max-width: 550px) {
    width: 100%;
    right: ${(props) => (props.sidebarOpen ? "0px" : "-100%")};
  }
`;
const SidebarHeader = styled.div`
  padding: 20px 0;
`;
const CloseBtn = styled.button`
  border: 0px;
  outline: none;
  background-color: transparent;
  padding: 10px;
`;

const SidebarContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Logo = styled.img`
  width: 175px;
  height: 50px;
`;
