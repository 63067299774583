import React from "react";
// Screens
import Landing from "../screens/Landing.jsx";

export const LandingPage = ({ blogs }) => {
  return (
    <>
      <Landing blogs={ blogs }/>
    </>
  );
}

