import React, { useState } from "react";
import TopNavbar from "../components/Nav/TopNavbar";
import Hero from "../components/Sections/Hero";
import Services from "../components/Sections/Services";
import AboutUs from "../components/Sections/AboutUs";
import HowWeDoIt from "../components/Sections/HowWeDoIt";
import BlogSection from "../components/Sections/BlogSection";
import Footer from "../components/Sections/Footer"
import Sidebar from "../components/Nav/Sidebar";
import Backdrop from "../components/Elements/Backdrop";
import ScrollToTopButton from "../components/Elements/ScrollToTop";

export default function Landing({ blogs }) {
  const [sidebarOpen, toggleSidebar] = useState(false);

  return (
    <>
      <Sidebar sidebarOpen={sidebarOpen} toggleSidebar={toggleSidebar} />
      {sidebarOpen && <Backdrop toggleSidebar={toggleSidebar} />}
      <TopNavbar sidebarOpen={sidebarOpen} toggleSidebar={toggleSidebar}/>
      <Hero sidebarOpen={sidebarOpen} toggleSidebar={toggleSidebar}/>
      <Services />
      <AboutUs />
      <HowWeDoIt />
      <ScrollToTopButton />
      <Footer />
    </>
  );
}


