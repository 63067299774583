import React from "react";
import styled from "styled-components";
import HeroImage from "../../assets/svg/HeroImage.jsx";
import FullButton from "../Buttons/FullButton";

const Hero = ({sidebarOpen, toggleSidebar}) => {
  return (
    <SectionWrapper>
      <ContentWrapper>
        <TextWrapper>
          <Title>Trust us to trust your AI Solutions.</Title>
          <Subtitle>
            We help you get aligned with the European AI Act and stay up to date with the newest regulations and updates.
          </Subtitle>
          <FullButton title="Contact us" action={() => toggleSidebar(!sidebarOpen)} width="200px" />
        </TextWrapper>
        <SvgWrapper>
          <HeroImage />
        </SvgWrapper>
      </ContentWrapper>
    </SectionWrapper>
  );
};

const SectionWrapper = styled.section`
  width: 100%;
  margin-top: 100px;
  min-height: calc(100vh - 100px);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 50px;
  gap: 20px;

  @media (max-width: 1350px){
    padding: 0 20px;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    text-align: center;
  }
`;


const SvgWrapper = styled.div`
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 768px) {
    width: 100%
  }
`;

const TextWrapper = styled.div`
  display: flex;
  width: 50%;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  @media (max-width: 768px) {
    align-items: center;
    width: 100%;
  }
`;

const Title = styled.h1`
  font-size: 3rem;
  color: var(--text);
  margin-bottom: 20px;
`;

const Subtitle = styled.p`
  font-size: 2rem;
  color: var(--secondarytext);
  line-height: 1.6;
  margin-bottom: 20px;
  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
  @media (max-width: 550px) {
    font-size: 1rem;
  }
`;

export default Hero;



