import React from "react";
import styled from "styled-components";
import { FaEnvelope, FaLinkedin } from "react-icons/fa";

export default function Footer() {
  return (
    <FooterContainer>
      <FooterContent className="container">
        <FooterRow>
          {/* Contact Information */}
          <FooterColumnWithBorder>
            <ContactBox>
              <FooterTitle>Contact Us</FooterTitle>
              <FooterLink href="mailto:contact@nmiguard.com">
                <FaEnvelope /> contact@nmiguard.com
              </FooterLink>
            </ContactBox>
          </FooterColumnWithBorder>

          {/* Social Media */}
          <FooterColumnWithBorder>
            <SocialBox>
              <FooterTitle>Follow Us</FooterTitle>
              <SocialLinks>
                <SocialIcon style={{gap: "5px", display: "flex", alignItems: "center", fontSize: "1rem"}} 
                href="https://www.linkedin.com/company/nmi-guard/" target="_blank" rel="noopener noreferrer">
                  <FaLinkedin />
                  NMI Guard
                </SocialIcon>
              </SocialLinks>
            </SocialBox>
          </FooterColumnWithBorder>

          {/* Terms and Conditions */}
          <FooterColumn>
            <LinksBox>
              <FooterTitle>Legal</FooterTitle>
              <FooterLink href="/terms-and-conditions">Terms and Conditions</FooterLink>
            </LinksBox>
          </FooterColumn>
        </FooterRow>
        <FooterBottom>
          <p>© Copyright 2025 | NMI Guard | All Rights Reserved</p>
        </FooterBottom>
      </FooterContent>
    </FooterContainer>
  );
}

const FooterContainer = styled.footer`
  background-color: var(--footer);
  color: #f2f2f2;
  padding: 10px 20px;
  font-family: 'Roboto', sans-serif;
`;

const FooterContent = styled.div`
  max-width: 1200px;
  margin: 0 auto;
`;

const FooterRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

const FooterColumn = styled.div`
  flex: 1;
  min-width: 200px;
  text-align: center;
  min-width: 33%;

  @media (max-width: 768px) {
    min-width: 100%;
  }
`;

const FooterColumnWithBorder = styled.div`
  flex: 1;
  min-width: 200px;
  text-align: center;
  border-right: 1px solid #b861b8;
  min-width: 33%;

  @media (max-width: 768px) {
    min-width: unset;
    border-right: none;
    padding: 5px;
    border-bottom: 1px solid #b861b8;
    min-width: 100%;
  }
`

const FooterTitle = styled.h5`
  font-size: 1.2rem;
  margin-bottom: 15px;
  color: #f2f2f2;
`;

const ContactBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const FooterLink = styled.a`
  justify-content: center;
  color: #f2f2f2;
  text-decoration: none;
  font-size: 1rem;
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  svg {
    margin-right: 8px;
  }

  &:hover {
    color: #b861b8;
  }
`;

const SocialBox = styled.div`
  text-align: center;
`;

const SocialLinks = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
`;

const SocialIcon = styled.a`
  font-size: 1.5rem;
  color: #f2f2f2;

  &:hover {
    color: #b861b8;
  }
`;

const LinksBox = styled.div`
  text-align: center;
`;

const FooterBottom = styled.div`
  text-align: center;
  border-top: 1px solid #b861b8;
  padding-top: 10px;
  font-size: 0.9rem;
  color: #b861b8;

  @media (max-width: 768px) {
    font-size: 0.8rem;
  }
`;
