import * as React from "react";
const SVGComponent = (props) => (
  <svg
    id="Layer_1"
    height={300}
    viewBox="0 0 512 512"
    width={300}
    xmlns="http://www.w3.org/2000/svg"
    data-name="Layer 1"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}
  >
    <g width="100%" height="100%" transform="matrix(1,0,0,1,0,0)">
      <path
        d="m255.988 98.244a38.791 38.791 0 1 0 -38.763-38.791 38.795 38.795 0 0 0 38.763 38.791zm0-60.508a21.717 21.717 0 1 1 -21.688 21.717 21.76 21.76 0 0 1 21.689-21.717zm-156.75 373.979a38.755 38.755 0 1 1 38.755-38.715 38.785 38.785 0 0 1 -38.755 38.719zm0-60.437a21.682 21.682 0 1 0 21.688 21.722 21.77 21.77 0 0 0 -21.688-21.718zm366.735-59.936c0 5.929-.285 11.93-.785 17.931a116.188 116.188 0 0 0 -140.155 27.5l-38.741-28.643a32.538 32.538 0 0 0 2.436-12.431 32.875 32.875 0 0 0 -24.2-31.647v-48.506a116.646 116.646 0 0 0 106.628-99.8 211.177 211.177 0 0 1 94.813 175.6zm-225.652 4.358a15.67 15.67 0 1 0 15.667-15.716 15.725 15.725 0 0 0 -15.667 15.716zm15.667-96.941a99.142 99.142 0 0 0 66.08-25.289 68.8 68.8 0 0 0 -132.161 0 99.172 99.172 0 0 0 66.081 25.289zm0-198.527a99.41 99.41 0 0 1 99.249 99.3 98.3 98.3 0 0 1 -20.11 59.722 85.9 85.9 0 0 0 -158.286 0 98.382 98.382 0 0 1 -20.095-59.724 99.4 99.4 0 0 1 99.242-99.299zm-209.972 291.11c0 5.929.272 11.93.786 17.931a116.188 116.188 0 0 1 140.155 27.5l38.743-28.643a32.823 32.823 0 0 1 21.76-44.078v-48.506a116.634 116.634 0 0 1 -106.622-99.8 211.02 211.02 0 0 0 -94.82 175.6zm53.222 22.5a99.165 99.165 0 0 0 -79.147 159.024 85.894 85.894 0 0 1 158.279 0 99.164 99.164 0 0 0 -79.132-159.021zm-66.095 173.169a68.822 68.822 0 0 1 132.154 0 99.178 99.178 0 0 1 -132.154 0zm153.557 2.643a212 212 0 0 0 138.59 0 116.139 116.139 0 0 1 -10.5-139.233l-38.871-28.721a32.916 32.916 0 0 1 -39.848 0l-38.871 28.721a116.141 116.141 0 0 1 -10.5 139.233zm159.971-2.643a68.832 68.832 0 0 1 132.168 0 99.2 99.2 0 0 1 -132.168 0zm145.229-14.145a99.242 99.242 0 1 0 -158.278 0 85.893 85.893 0 0 1 158.278 0zm-79.141-138.661a38.755 38.755 0 1 0 38.77 38.795 38.85 38.85 0 0 0 -38.77-38.791zm0 60.436a21.682 21.682 0 1 1 21.703-21.641 21.656 21.656 0 0 1 -21.7 21.645z"
        fill="#b861b8"
        fillRule="evenodd"
        fillOpacity={1}
        data-original-color="#fc0005ff"
        stroke="none"
        strokeOpacity={1}
      />
    </g>
  </svg>
);
export default SVGComponent;
